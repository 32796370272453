<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="card-alert">
        <el-alert type="info" show-icon :closable="false">
          <template slot="title">
            <ul>
              <li>请填写正确的银行卡信息，以免提现失败，提交申请后，平台会尽快处理您的提现申请</li>
              <li>帐户余额大于1000时才可提现</li>
              <!--              <li>每个月只能提交一次提现申请</li>-->
            </ul>
          </template>
        </el-alert>
      </el-card>
      <el-card shadow="never" header="可提现金额" class="mb10">
        <span class="money">{{ currentAccount.balance }}</span> 元
        <el-button type="text" @click="handleOpenDialog" v-if="balanceIsEnough">提现</el-button>
      </el-card>
      <el-card shadow="never" header="提现记录">
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column prop="creationTime" label="提现申请时间" width="160"></el-table-column>
              <el-table-column prop="amount" label="金额(元)"></el-table-column>
              <el-table-column prop="bankCardNumber" label="帐户">
                <template slot-scope="scope">
                  <div>{{ scope.row.accountName }}</div>
                  <div>{{ scope.row.bankName }}</div>
                  <div>{{ scope.row.bankCardNumber }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                  <dict-text :code="scope.row.status" dict-name="withdrawStatus"></dict-text>
                </template>
              </el-table-column>
              <el-table-column prop="auditTime" label="到账时间"></el-table-column>
              <el-table-column prop="auditRemark" label="备注"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>

        <el-dialog
            title="提现申请"
            :visible.sync="dialogVisible"
            width="40%">
          <el-form :model="editForm" :rules="rules" ref="editForm" label-width="120px">
            <el-form-item label="可提现金额">
              <span class="money">{{ currentAccount.balance }}</span> 元
            </el-form-item>
            <el-form-item label="本次提现金额" prop="amount">
              <el-input v-model.number="editForm.amount"></el-input>
            </el-form-item>
            <el-form-item label="户名" prop="accountName">
              <el-input v-model="editForm.accountName"></el-input>
              <el-popover
                  placement="right"
                  width="400"
                  v-model="bankPopoverShow"
                  trigger="click">
                <el-table :data="bankList" @row-click="handleBankSelected" style="width: 100%">
                  <el-table-column prop="bankCardOwner" label="户名" width="80"></el-table-column>
                  <el-table-column prop="cardNumber" label="卡号"></el-table-column>
                  <el-table-column prop="bankName" label="开户行"></el-table-column>
                </el-table>
                <el-button slot="reference" type="text">选择银行卡</el-button>
              </el-popover>
            </el-form-item>
            <el-form-item label="开户行" prop="bankName">
              <el-input v-model="editForm.bankName"></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="bankCardNumber">
              <el-input v-model="editForm.bankCardNumber"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button plain @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="submitLoading">提交申请</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AccountWithdrawApi, AccountBankCardApi, AccountApi } from '@/api'
import { SHORTCUTS } from '@/utils/contants'
import { validDealPassword } from '@/utils/password-valid'

const defaultForm = () => {
  return {
    id: '',
    accountName: '',
    bankName: '',
    bankCardNumber: '',
    amount: ''
  }
}

export default {
  name: 'BalanceWithdraw',
  data () {
    return {
      currentAccount: {},
      searchForm: {
        startTime: '',
        endTime: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      dateRange: [],
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      dialogVisible: false,
      submitLoading: false,
      editForm: defaultForm(),
      rules: {
        bankCardNumber: [
          {
            required: true,
            message: '请输入银行卡号',
            trigger: 'blur'
          }
        ],
        accountName: [
          {
            required: true,
            message: '请输入户名',
            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: '请输入开户行',
            trigger: 'blur'
          }
        ],
        amount: [
          {
            required: true,
            message: '提现金额不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const val = Number(value) * 100
              const balance = Number(this.currentAccount.balance) * 100
              if (val > balance) {
                callback(new Error('提现金额不能大于帐户余额'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          },
          {
            type: 'number',
            pattern: /(^[1-9]\d*$)/,
            message: '请输入正确的金额，只能输入正整数',
            trigger: 'blur'
          }
        ]
      },
      bankList: [],
      bankPopoverShow: false
    }
  },
  computed: {
    balanceIsEnough () {
      const balance = Number(this.currentAccount.balance) * 100
      return balance >= 1000 * 100
    }
  },
  methods: {
    getAccount () {
      AccountApi.getAccount().then(res => {
        this.currentAccount = res
      })
    },
    async initData () {
      this.getAccount()
      const bankCardRes = await AccountBankCardApi.getList({
        page: 0,
        size: 50
      })
      this.bankList = bankCardRes.rows
      const bankDefault = this.bankList.find(it => it.isDefault)
      if (bankDefault) {
        this.editForm.bankCardNumber = bankDefault.cardNumber
        this.editForm.accountName = bankDefault.bankCardOwner
        this.editForm.bankName = bankDefault.bankName
      }
    },
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }

      if (this.dateRange && this.dateRange.length === 2) {
        params.startTime = this.dateRange[0]
        params.endTime = this.dateRange[1]
      }
      AccountWithdrawApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleTabsClick () {
      this.handleCurrentChange(1)
    },
    handleOpenDialog () {
      validDealPassword().then(() => {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.editForm.resetFields()
          this.editForm = defaultForm()
        })
      })
    },
    handleSubmit () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          AccountWithdrawApi.submitApply(this.editForm).then(() => {
            this.submitLoading = false
            this.dialogVisible = false
            this.$message.success('提交成功')
            this.handleCurrentChange(1)
            this.getAccount()
          }).catch(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleBankSelected (row) {
      this.editForm.bankCardNumber = row.cardNumber
      this.editForm.accountName = row.bankCardOwner
      this.editForm.bankName = row.bankName
      this.bankPopoverShow = false
    }
  },
  created: function () {
    this.initData()
    this.handleSearch()
  }
}
</script>

<style scoped lang="less">
.money {
  font-size: 20px;
  font-weight: bold;
}
</style>
